import axios from '@/libs/axios'
import helpers from '@/libs/helper'

import appActions from '@/store/tt/actions/appActions'
import authActions from '@/store/tt/actions/authActions'
import userActions from '@/store/tt/actions/userActions'
import logActions from '@/store/tt/actions/logActions'
import countryActions from '@/store/tt/actions/countryActions'
import countyActions from '@/store/tt/actions/countyActions'
import entityActions from '@/store/tt/actions/entityActions'
import fileActions from '@/store/tt/actions/fileActions'
import areaCodeActions from '@/store/tt/actions/areaCodeActions'
import optionActions from '@/store/tt/actions/optionActions'
import phoneNumberActions from '@/store/tt/actions/phoneNumberActions'
import phoneNumberCommentActions from '@/store/tt/actions/phoneNumberCommentActions'
import phoneNumberTagActions from '@/store/tt/actions/phoneNumberTagActions'
import pageActions from '@/store/tt/actions/pageActions'
import dashboardActions from '@/store/tt/actions/dashboardActions'
import categoryActions from '@/store/tt/actions/categoryActions'
import tagActions from '@/store/tt/actions/tagActions'
import postActions from '@/store/tt/actions/postActions'
import adSenseAdActions from '@/store/tt/actions/adSenseAdActions'
import redirectActions from '@/store/tt/actions/redirectActions'
import store from '@/store'

// setup axios
axios.interceptors.request.use(
    config => {
        const accessToken = store.state.token
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        if (process.env.NODE_ENV === 'development') console.log('Interceptor request[', config.method, '+', config.url, ']', config);
        return config
    },
    error => {
        // Do something with request error
        helpers.showErrorToast('', 'Ismeretlen hiba! Keresse fel az oldal üzemeltetőjét')
        if (process.env.NODE_ENV === 'development') console.error('Interceptor request error: ', error);
        return Promise.reject(error)
    },
)

axios.interceptors.response.use(
    //If we have a response from our recent http call
    (response) => {
        if (process.env.NODE_ENV === 'development') console.log('Interceptor response[', response.config.method, '+', response.config.url, ']', response);
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            helpers.showErrorToast('Lejárt munkamenet!')
            helpers.logout()
            // eslint-disable-next-line no-underscore-dangle
        }
        //Catches 403 error from our axios request
        if (process.env.NODE_ENV === 'development') console.error('Interceptor response error: ', error);
        return Promise.reject(error);
    }
);

const actions = {
    ...appActions,
    ...authActions,
    ...userActions,
    ...countryActions,
    ...countyActions,
    ...areaCodeActions,
    ...optionActions,
    ...phoneNumberActions,
    ...phoneNumberCommentActions,
    ...phoneNumberTagActions,
    ...pageActions,
    ...categoryActions,
    ...tagActions,
    ...postActions,
    ...redirectActions,
    ...dashboardActions,
    ...fileActions,
    ...entityActions,
    ...logActions,
    ...adSenseAdActions,
}

export default actions
