export default [
  {
    path: '/phone-number-tags',
    name: 'phone-number-tags',
    component: () => import('@/views/pages/phoneNumberTag/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.phone-number-tags',
      resource: 'tag',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.phone-number-tags',
          active: true,
        },
      ],
    },
  },
  {
    path: '/phone-number-tags/add',
    name: 'add-phone-number-tag',
    component: () => import('@/views/pages/phoneNumberTag/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.phone-number-tags',
      resource: 'tag',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.phone-number-tags',
          active: false,
          to: {name: 'phone-number-tags'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/phone-number-tags/modify/:id',
    name: 'modify-phone-number-tag',
    component: () => import('@/views/pages/phoneNumberTag/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.phone-number-tags',
      resource: 'tag',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.phone-number-tags',
          active: false,
          to: {name: 'phone-number-tags'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
